import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import loadable from '@loadable/component'

const LoadComponent = loadable(() => import('../components/typeformEmbed'))

const StartProject = props => (
  <Layout footerShow="hide" style={{ height: '100%' }}>
    <SEO title="Start a Project" keywords={['start']} />
    <LoadComponent id="ICtvai" />
  </Layout>
)

export default StartProject
